export interface Assignment {
  id: string;
  isMyAssignment: boolean;
  creatorUserId: string;
  creatorUser: AssignmentCreatorUser;
  state: AssignmentState;
  createdAtUtc: string;
  desiredBudget: AssignmentDesiredBudget;
  paymentType: AssignmentWayToPay;
  title: string;
  description: string;
  categoryId: string;
  category: string;
  subcategory: string;
  media: AssignmentMedia[];
  location?: AssignmentLocation;
  desiredExecutionTime?: AssignmentDesiredExecutionTime;
}

export interface AssignmentCreatorUser {
  userId: string;
  firstName: string;
  lastName: string;
  photoUrl: string | null;
}

export interface AssignmentCategory {
  id: string;
  name: string;
  subcategories: AssignmentSubCategory[];
}

export interface AssignmentSubCategory {
  id: string;
  name: string;
}

export interface AssignmentMedia {
  id: string;
  url: string;
  mimeType: string;
}

export interface AssignmentDesiredExecutionTime {
  dateRange?: {
    lowerBound: string;
    uppedBound: string;
  };
  dateTime?: string;
}

export interface AssignmentDesiredBudget {
  amount: number;
  currency: string;
}

export interface AssignmentLocation {
  coordinates: {
    latitude: number;
    longitude: number;
  };
  formattedAddress: string;
  googlePlaceId: string;
}

export enum AssignmentWayToPay {
  SafeDeal = 'SafeDeal',
  Cash = 'Cash',
}

export enum AssignmentState {
  Published = 'Published',
  InProgress = 'InProgress',
  Hidden = 'Hidden',
  Completed = 'Completed',
}
